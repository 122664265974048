const ROLES_FOR_APP = {
    DASHBOARD_VIEW: "view",

    ADVERTISING: "advertising",
    REPORT_ADVERTISING: "report_advertising",
    REPORT_DAILY: "report_daily",
};

const isHavePermissionRole = (keyRoles: string | string[], permissions: { id: string | number, name: string }[] = []): boolean => {
    const roles = typeof keyRoles === 'string' ? [keyRoles] : keyRoles;
    return true; // permissions?.some(item => roles?.includes(item.name));
};

export { ROLES_FOR_APP, isHavePermissionRole };