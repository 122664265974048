import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//Import Icons
import { useRole } from "components/Hooks/UserHooks";
import config from "config";
import FeatherIcon from "feather-icons-react";
import { ROLES_FOR_APP, isHavePermissionRole } from "helpers/role";
import { useTranslation } from "react-i18next";
import { ROUTES } from "routes/allRoutes";

const Navdata = () => {
    const history = useNavigate();
    const { t } = useTranslation();
    const { userPermissions } = useRole();

    const [menuActive, setMenuActive] = useState<string>('DASHBOARD');

    // Multi Level
    const [isLevel1, setIsLevel1] = useState<boolean>(false);

    const [isCurrentState, setIsCurrentState] = useState('Dashboard');

    function updateIconSidebar(e: any) {
        if (e && e.target && e.target.getAttribute("sub-items")) {
            const ul: any = document.getElementById("two-column-menu");
            const iconItems = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("sub-items");
                const getID = document.getElementById(id) as HTMLElement
                if (getID)
                    getID.classList.remove("show");
            });
        }
    }

    const redirectMessageHub = () => {
        const { message_hub_sso_redirect_url } = config
        const accessToken: any = localStorage.getItem("access_token");

        if (message_hub_sso_redirect_url && accessToken) {
            return `${message_hub_sso_redirect_url}?sso_token_newshub=${accessToken}`
        } else {
            return '/#'
        }
    }

    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');
    }, [history]);

    const menuItems: any = [
        // {
        //     id: "dashboard",
        //     label: t('Dashboards'),
        //     icon: <FeatherIcon icon="home" className="icon-dual" />,
        //     link: ROUTES.DASHBOARD,
        //     click: function (e: any) {
        //         setMenuActive((prev) => 'DASHBOARD');
        //         setIsCurrentState((prev) => 'Dashboard');
        //         updateIconSidebar(e);
        //     },
        //     stateVariables: (menuActive === 'DASHBOARD'),
        //     isShow: isHavePermissionRole(ROLES_FOR_APP.DASHBOARD_VIEW, userPermissions),
        //     // subItems: []
        // },
        {
            id: "multilevel2",
            label: t('Report Advertising'),
            icon: <FeatherIcon icon="clipboard" className="icon-dual" />,
            link: ROUTES.REPORT_ADVERTISING,
            click: function (e: any) {
                setMenuActive((prev) => 'REPORT_ADVERTISING');
                setIsCurrentState((prev) => "Report Advertising");
                updateIconSidebar(e);
            },
            stateVariables: (menuActive === 'REPORT_ADVERTISING'),
            isShow: isHavePermissionRole([ROLES_FOR_APP.REPORT_ADVERTISING], userPermissions),

        },

        {
            id: "multilevel2",
            label: t('Report Daily'),
            icon: <FeatherIcon icon="clipboard" className="icon-dual" />,
            link: ROUTES.REPORT_DAILY,
            click: function (e: any) {
                setMenuActive((prev) => 'REPORT_DAILY');
                setIsCurrentState((prev) => "Report Daily");
                updateIconSidebar(e);
            },
            stateVariables: (menuActive === 'REPORT_DAILY'),
            isShow: isHavePermissionRole([ROLES_FOR_APP.REPORT_DAILY], userPermissions),

        },
        // {
        //     id: "logout",
        //     label: "Logout",
        //     icon: <FeatherIcon icon="log-out" className="icon-log-out" />,
        //     link: "/logout",
        //     stateVariables: isDashboard,
        //     click: function (e: any) {
        //         e.preventDefault();
        //         setIsDashboard(!isDashboard);
        //         setIsCurrentState( (prev) =>  'Dashboard');
        //         updateIconSidebar(e);
        //     },
        // },
    ];
    return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
