import { createAsyncThunk } from "@reduxjs/toolkit";
import reportApi from "api/reportApi";
import { formatQueryParams } from "helpers/format";

export const advertisingReports = createAsyncThunk("Advertising Report", async (params: any = {}) => {
  try {
    const response = await reportApi.advertisingReports(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const dailyReports = createAsyncThunk("Daily Report", async (params: any = {}) => {
  try {
    const response = await reportApi.reportDaily(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});