import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from "react-countup";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import { IAdvertising } from "../../../api/types/_advertising";
import { Option } from "../../../api/types/_public";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import LabelWrapper from "../../../components/Common/LabelWrapper";
import TableContainer from "../../../components/Common/TableContainer";
import { useRole } from "../../../components/Hooks/UserHooks";
import { formatNumberWithCommas } from "../../../helpers/format";
import { isHavePermissionRole, ROLES_FOR_APP } from "../../../helpers/role";
import { dailyReports as onGetReportDaily } from "../../../store/thunks";
import ChartLine from "./ChartLine";

const SORT_BY_DEFAULT = 'date';
const ORDER_BY_DEFAULT = 'DESC';

registerLocale('en', en);
registerLocale('ko', ko);

const generateYearOptions = () => {
    const currentYear = new Date().getFullYear(); // get year now
    const yearOptions = [];
    for (let i = 0; i <= 15; i++) {
        const year: number = currentYear - i;
        yearOptions.push({ value: String(year), label: String(year) });
    }

    return yearOptions;
};

const ReportDaily = () => {
    const { t, i18n } = useTranslation();
    const { userPermissions } = useRole();
    const navigate = useNavigate();

    const OPTION_YEARS: Option[] = generateYearOptions();
    const [query, setQuery]: any = useQueryParams({
        time_request: withDefault(NumberParam, 0),
        page: withDefault(NumberParam, 1),
        limit: withDefault(NumberParam, 100),
        sort_by: withDefault(StringParam, SORT_BY_DEFAULT),
        order_by: withDefault(StringParam, ORDER_BY_DEFAULT),
        date: withDefault(StringParam, '2023-01'),
        // start_date: withDefault(StringParam, `${moment().startOf('year').format("Y-MM")}`),
        // end_date: withDefault(StringParam, moment().endOf('year').format("Y-MM")),
    });

    const [dateSearch, setDateSearch] = useState<string>(query?.date || "");

    const dispatch: any = useDispatch();

    const selectLayoutState = (state: any) => state.Report;

    const ReportProperties = createSelector(
        selectLayoutState,
        (state) => ({
            advertising: state.advertising,
            isAdvertisingSuccess: state.isAdvertisingSuccess,
            isAdvertisingLoading: state.isAdvertisingLoading,
            error: state.error,
        })
    );

    const { advertising, isAdvertisingLoading } = useSelector(ReportProperties);

    // const [yearSearch, setYearSearch] = useState<any>(OPTION_YEARS?.find((item) => String(item?.value) === String(query?.date)) || OPTION_YEARS[0]);

    // const [dateSearch, setDateSearch] = useState<any[]>([query?.start_date ? moment(query?.start_date, 'Y-MM-DD').toDate() : null, query?.end_date ? moment(query?.end_date || '', 'Y-MM-DD').toDate() : null]);
    // const [startDate, endDate] = dateSearch;


    const [listAdvertising, setListAdvertising] = useState<IAdvertising[]>([]);
    const [advertisingSearch, setAdvertisingSearch] = useState<Option | null>({
        label: listAdvertising?.find((item) => String(item?.id || '') === String(query?.advertising_id || ''))?.title || t('All Advertising'),
        value: `${listAdvertising?.find((item) => String(item?.id || '') === String(query?.advertising_id || ''))?.id || ''}`
    });

    useEffect(() => {
        dispatch(onGetReportDaily(query));
    }, [JSON.stringify(query)]);

    const searchData = () => {
        setQuery({
            ...query,
            page: 1,
            date: dateSearch || '',
            // start_date: startDate ? moment(new Date(startDate)).format("Y-MM") : '',
            // end_date: endDate ? moment(new Date(endDate)).format("Y-MM") : '',
            time_request: + new Date()
        });
    };

    const resetData = () => {
        const queryNew = {
            ...query,
            date: '2023-01',
            // start_date: `${moment().startOf('year').format("Y-MM")}`,
            // end_date: `${moment().endOf('year').format("Y-MM")}`,
            sort_by: SORT_BY_DEFAULT,
            order_by: ORDER_BY_DEFAULT,
            time_request: + new Date(),
            page: 1,
        };
        setQuery(queryNew, "push");
        setDateSearch(queryNew?.date);
        // setYearSearch(OPTION_YEARS[0]);
        // setDateSearch([moment(queryNew?.start_date || "", 'Y-MM').toDate(), moment(queryNew?.end_date || "", 'Y-MM').toDate()]);
        // setAdvertisingSearch({ label: t('All Advertising'), value: '' });
    };

    const handleChangePicker = (value: Date | null) => {
        setDateSearch((_prev) => value ? moment(new Date(value)).format("Y-MM") : '');
    }

    // Column
    const columns = useMemo(
        () => [
            {
                Header: t('Date'),
                accessor: "date",
                filterable: true,
                sortable: true,
                thWidth: 180,
                thClass: 'align-middle text-start',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        !!item?.text_row ? <strong>{item?.text_row} </strong> :
                            <div className="text-start" style={{ minWidth: '80px' }}>
                                <span>{cell?.value}</span>
                            </div>
                    )
                },
            },
            {
                Header: t('Total Price'),
                accessor: "total_price",
                filterable: true,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 150,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3 " style={{ minWidth: '150px' }}>{formatNumberWithCommas(cell?.value)}</div>
                        </>
                    )
                },
            },
            {
                Header: t('App Targeting Price'),
                accessor: "app_targeting_price",
                filterable: true,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 200,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3 " style={{ minWidth: '180px' }}>{formatNumberWithCommas(cell?.value)}</div>
                        </>
                    )
                },
            },
            {
                Header: t('App Targeting Count'),
                accessor: "app_targeting_count",
                filterable: true,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 200,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3 " style={{ minWidth: '180px' }}>{formatNumberWithCommas(cell?.value)}</div>
                        </>
                    )
                },
            },
            {
                Header: t('Location Targeting Price'),
                accessor: "location_targeting_price",
                filterable: true,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 200,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3 " style={{ minWidth: '200px' }}>{formatNumberWithCommas(cell?.value)}</div>
                        </>
                    )
                },
            },
            {
                Header: t('Location Targeting Count'),
                accessor: "location_targeting_count",
                filterable: true,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 200,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3 " style={{ minWidth: '200px' }}>{formatNumberWithCommas(cell?.value)}</div>
                        </>
                    )
                },
            },
            {
                Header: t('User Targeting Price'),
                accessor: "user_targeting_price",
                filterable: true,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 200,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3 " style={{ minWidth: '180px' }}>{formatNumberWithCommas(cell?.value)}</div>
                        </>
                    )
                },
            },
            {
                Header: t('User Targeting Count'),
                accessor: "user_targeting_count",
                filterable: true,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 200,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3 " style={{ minWidth: '180px' }}>{formatNumberWithCommas(cell?.value)}</div>
                        </>
                    )
                },
            },
        ],
        [i18n?.language, userPermissions]
    );

    const handleChangePage = useCallback((page: any) => {
        setQuery({ page: page + 1 })
    }, []);

    const handleChangeSorting = useCallback((sortBy: any) => {
        setQuery((_prev: any) => {
            return ({ ..._prev, ...sortBy });
        });
    }, []);

    // const handleChangePicker = (values: any[] = []) => {
    //     setDateSearch((_prev: any) => values);
    // }

    useEffect(() => {
        document.title = `${t("Report Daily")}`;
        document.body.classList.remove("vertical-sidebar-enable");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    const categories = useMemo(() => {
        return advertising?.list?.map((item: any) => item?.date).reverse() || [];
    }, [advertising?.list, i18n?.language]);

    const series = useMemo(() => {
        const obChart = advertising?.list?.reduce((ob: any, item: any) => {
            ob.total_price?.push(Number(String(item?.total_price).replace(/,/g, '')));
            return ob;
        }, { total_price: [] }) || [];
        return (
            [
                {
                    name: t('Total Price'),
                    type: 'bar',
                    data: obChart?.total_price?.reverse(),
                },
            ]
        )
    }, [advertising?.list, i18n?.language]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={t('Report Advertising')} pageTitle={t('Report Advertising')} />
                    {isHavePermissionRole(ROLES_FOR_APP.REPORT_ADVERTISING, userPermissions) && (
                        <Row>
                            <Col sm={12} md={12}>
                                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }} className="ribbon-box right">
                                    {/* <div className="ribbon round-shape" style={{ background: 'rgb(40 99 213)', fontWeight: 300, fontSize: '0.8rem', top: '11px' }}>{t('This data is analyzed per 10 mins')}</div> */}
                                    <CardHeader className="border-0" style={{ borderRadius: '30px' }}>
                                        <Row className="g-4 align-items-center">
                                            <div className="col-sm">
                                                <div>
                                                    <h5 className="card-title mb-0">
                                                        <span className="me-2">{t('Total')}:</span>
                                                        <CountUp
                                                            start={0}
                                                            end={advertising?.pagination?.total || 0}
                                                            duration={1}
                                                            className="text-primary"
                                                        />
                                                    </h5>
                                                </div>
                                            </div>
                                        </Row>
                                    </CardHeader>
                                    <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                                        <Row className="g-4 align-items-center mt-0">
                                            <Col sm={4} md={3} xl={2} xxl={2} className='date-picker-wrapper-custom mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Date')} isShow={true}>
                                                    {/* <DatePickerCustom
                                                        placeholder={`${t('Start Date')} - ${t('End Date')}`}
                                                        startDate={startDate || null}
                                                        endDate={endDate || null}
                                                        dateFormat="yyyy-MM"
                                                        onChangePicker={handleChangePicker}
                                                        showOptions={[
                                                            'this_month', 'last_month', 'last_3_month', 'last_6_month', 'this_year', 'last_year'
                                                        ]}
                                                    /> */}

                                                    <DatePicker
                                                        className="form-control search"
                                                        placeholderText={`${t('Date')}...`}
                                                        value={dateSearch || ''}
                                                        selected={dateSearch ? moment(dateSearch, 'Y-MM').toDate() : null}
                                                        showMonthYearPicker
                                                        showFullMonthYearPicker
                                                        showFourColumnMonthYearPicker
                                                        dateFormat="yyyy-MM"
                                                        isClearable={true}
                                                        locale={i18n?.language === 'ko' ? 'ko' : 'en'}
                                                        onChange={handleChangePicker}
                                                    />
                                                    {/* <DropdownOption
                                                        name="inventory_type"
                                                        dataList={OPTION_YEARS || []}
                                                        placeholder={`${t("Inventory Type")}...`}
                                                        className="dropdown-status-rounded"
                                                        classNamePrefix="name-prefix"
                                                        initialValue={yearSearch || OPTION_YEARS[0]}
                                                        onChangeSelect={(e: any) => setYearSearch(e)}
                                                        isHasOptionAll={false}
                                                        optionAll={{ label: t('All Type'), value: '' }}
                                                    /> */}
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={8} md={9} xl={10} xxl={10} className="hstack gap-1 justify-content-center justify-content-md-between mt-3 mt-md-2">
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary me-2 "
                                                        onClick={searchData}
                                                        disabled={isAdvertisingLoading}
                                                    >
                                                        <i className="ri-search-line align-bottom me-1"></i>{" "}
                                                        {t('Button Search')}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary fs-14"
                                                        onClick={resetData}
                                                    >
                                                        <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                                        {t('Button Reset')}
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <ChartLine
                                        className="my-3"
                                        titles={[t('Total Price')]}
                                        categories={categories}
                                        series={series}
                                    />
                                    <CardBody className="pt-3">
                                        <TableContainer
                                            className="custom-header-css"
                                            divClass="table-responsive table-card"
                                            tableClass="align-middle table-bordered-dashed table-have-first-row-is-total"
                                            theadClass="table-light text-muted"
                                            columns={columns}
                                            data={advertising?.list?.length ? (
                                                [
                                                    {
                                                        ...advertising?.total,
                                                        text_row: t('Sum')
                                                    },
                                                    ...advertising?.list]
                                            ) : []}
                                            customPageSize={query.limit}
                                            customPageIndex={query.page - 1}
                                            totalRecords={advertising?.pagination?.total}
                                            customPageCount={Math.ceil(Number(advertising?.pagination?.total) / Number(advertising?.pagination?.limit))}
                                            handleChangePage={handleChangePage}
                                            manualSorting={true}
                                            sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                                            handleChangeSorting={handleChangeSorting}
                                            isLoading={isAdvertisingLoading}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    )}
                    <ToastContainer closeButton={false} limit={1} />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ReportDaily;
