import { Navigate } from "react-router-dom";

//Dashboard
import DashboardCrm from "../pages/Dashboard";

//pages
import ComingSoon from '../pages/Pages/ComingSoon/ComingSoon';
import Settings from '../pages/Pages/Profile/Settings/Settings';

//login
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";

// User Profile

import Profile from "pages/User/Profile";
import UserProfile from "../pages/Authentication/user-profile";

import Advertising from "pages/Advertising";
import ReportAdvertising from "pages/Reports/Advertising";
import ReportDaily from "pages/Reports/Daily";

const ROUTES = {
  DASHBOARD: '/dashboard',

  PROFILE: '/profile',
  PROFILE_SETTING: '/pages-profile-settings',

  REPORT_ADVERTISING: '/report',
  REPORT_DAILY: '/report-daily',
  ADVERTISING: '/advertising'
}

const authProtectedRoutes = [
  { path: ROUTES.DASHBOARD, component: <DashboardCrm /> },

  { path: ROUTES.PROFILE_SETTING, component: <Settings /> },
  { path: ROUTES.PROFILE, component: <UserProfile /> },

  { path: ROUTES.ADVERTISING, component: <Advertising /> },
  { path: ROUTES.REPORT_ADVERTISING, component: <ReportAdvertising /> },
  { path: ROUTES.REPORT_DAILY, component: <ReportDaily /> },

  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  { path: "*", component: <Navigate to="/dashboard" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },

  //AuthenticationInner pages
  { path: "/pages-coming-soon", component: <ComingSoon /> },
];

export { authProtectedRoutes, publicRoutes, ROUTES };

