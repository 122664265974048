import { createSlice } from "@reduxjs/toolkit";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { IAdvertisingReport } from "api/types/_report";
import { advertisingReports, dailyReports } from "./thunk";

export interface IState {
  advertising: ResponseData<IAdvertisingReport[]> & PaginationResponse | null,
  isAdvertisingLoading: boolean,
  isAdvertisingSuccess: boolean,

  error: any,
};

export const initialState: IState = {
  advertising: null,
  isAdvertisingLoading: false,
  isAdvertisingSuccess: false,

  error: {},
};

const ReportSlice = createSlice({
  name: "Report",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //report
    builder.addCase(advertisingReports.pending, (state: IState, action: any) => {
      state.isAdvertisingLoading = true
    });
    builder.addCase(advertisingReports.fulfilled, (state: IState, action: any) => {
      state.advertising = action.payload.data;
      state.isAdvertisingSuccess = true;
      state.isAdvertisingLoading = false;
    });
    builder.addCase(advertisingReports.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isAdvertisingSuccess = false;
      state.isAdvertisingLoading = false;
    });

    // daily report 
    builder.addCase(dailyReports.pending, (state: IState, action: any) => {
      state.isAdvertisingLoading = true
    });
    builder.addCase(dailyReports.fulfilled, (state: IState, action: any) => {
      state.advertising = action.payload.data;
      state.isAdvertisingSuccess = true;
      state.isAdvertisingLoading = false;
    });
    builder.addCase(dailyReports.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isAdvertisingSuccess = false;
      state.isAdvertisingLoading = false;
    });
  },
});


export default ReportSlice.reducer;